<script setup lang="ts">
type IFFLogoMode = "light" | "dark" | "primary"
const props = defineProps({
  mode: {
    type: String,
    default: "primary",
  },
})

const modeClass = computed(() => `ff-${props.mode}`)
</script>
<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 312.9 61.7"
    style="enable-background: new 0 0 312.9 61.7"
    xml:space="preserve"
  >
    <path
      id="Path_213"
      :class="[modeClass]"
      d="M98.4,14.1v11.1h-1.7V11.4h1.7l8,10.9V11.4h1.7v13.8h-1.6L98.4,14.1z"
    ></path>
    <path
      id="Path_214"
      :class="[modeClass]"
      d="M118.6,11.1c4.1,0,6.9,3.1,6.9,7.2s-2.8,7.2-6.9,7.2s-6.9-3.1-6.9-7.2S114.4,11.1,118.6,11.1
	 M118.6,12.6c-3.1,0-5.1,2.4-5.1,5.7c0,3.2,2,5.7,5.1,5.7s5.1-2.4,5.1-5.7S121.7,12.6,118.6,12.6"
    ></path>
    <path
      id="Path_215"
      :class="[modeClass]"
      d="M133.8,19.7H131v5.5h-1.7v-14h5.6c2.2-0.2,4.2,1.5,4.4,3.7c0,0.2,0,0.3,0,0.5
	c0.1,2.1-1.5,3.9-3.6,4.1l3.8,5.7h-2.1L133.8,19.7z M134.7,12.9H131v5.3h3.7c1.4,0.1,2.7-1,2.8-2.4c0-0.1,0-0.2,0-0.2
	c0-1.4-1.1-2.6-2.5-2.7C134.8,12.8,134.7,12.8,134.7,12.9"
    ></path>
    <path
      id="Path_216"
      :class="[modeClass]"
      d="M149.6,11.1c2.1,0,4.1,0.9,5.4,2.6l-1.4,0.9c-1-1.2-2.5-1.9-4-1.9c-3,0-5.4,2.4-5.4,5.3
	c0,0.1,0,0.2,0,0.3c-0.2,3,2.1,5.5,5.1,5.7c0.1,0,0.2,0,0.3,0c1.4,0,2.7-0.5,3.7-1.5v-2.8h-4.8v-1.5h6.5v5c-1.4,1.6-3.4,2.4-5.5,2.4
	c-3.9,0.1-7.1-3-7.2-6.8c0-0.1,0-0.3,0-0.4c-0.1-3.9,2.9-7.1,6.7-7.2C149.3,11.1,149.5,11.1,149.6,11.1"
    ></path>
    <path
      id="Path_217"
      :class="[modeClass]"
      d="M159,11.3h9.1v1.5h-7.4v4.5h7.3v1.5h-7.3v4.8h7.4v1.5H159V11.3z"
    ></path>
    <path
      id="Path_218"
      :class="[modeClass]"
      d="M172.1,22c1.1,1.3,2.7,2,4.4,2c2.5,0,3.3-1.3,3.3-2.4c0-1.7-1.7-2.1-3.5-2.6
	c-2.2-0.6-4.7-1.2-4.7-4c0-2.3,2.1-3.9,4.8-3.9c1.9-0.1,3.7,0.6,5,1.9l-1,1.3c-1-1.1-2.5-1.7-4-1.7c-1.7,0-2.9,0.9-2.9,2.2
	c0,1.4,1.6,1.8,3.4,2.3c2.3,0.6,4.9,1.3,4.9,4.2c0,2-1.4,4.1-5.1,4.1c-2,0.1-4-0.7-5.4-2.2L172.1,22z"
    ></path>
    <path
      id="Path_219"
      :class="[modeClass]"
      d="M96.4,36.4h9.1v1.5h-7.4v4.5h7.2V44H98v6.4h-1.7v-14H96.4z"
    ></path>
    <path
      id="Path_220"
      :class="[modeClass]"
      d="M115.3,36.2c4.1,0,6.9,3.1,6.9,7.2s-2.8,7.2-6.9,7.2s-6.9-3.1-6.9-7.2S111.1,36.2,115.3,36.2
	 M115.3,37.7c-3.1,0-5.1,2.4-5.1,5.7c0,3.2,2,5.7,5.1,5.7s5.1-2.4,5.1-5.7C120.4,40.1,118.4,37.7,115.3,37.7"
    ></path>
    <path
      id="Path_221"
      :class="[modeClass]"
      d="M128.8,37.9h-4.4v-1.5H135v1.5h-4.4v12.4h-1.7L128.8,37.9z"
    ></path>
    <path
      id="Path_222"
      :class="[modeClass]"
      d="M144.2,36.2c4.1,0,6.9,3.1,6.9,7.2s-2.8,7.2-6.9,7.2c-4.1,0-6.9-3.1-6.9-7.2S140,36.2,144.2,36.2
	 M144.2,37.7c-3.1,0-5.1,2.4-5.1,5.7c0,3.2,2,5.7,5.1,5.7s5.1-2.4,5.1-5.7C149.3,40.1,147.3,37.7,144.2,37.7"
    ></path>
    <path
      id="Path_223"
      :class="[modeClass]"
      d="M161.4,36.2c2.1,0,4.1,0.9,5.4,2.6l-1.4,0.9c-1-1.2-2.5-1.9-4-1.9c-3,0-5.4,2.4-5.4,5.3
	c0,0.1,0,0.2,0,0.3c-0.2,3,2.1,5.5,5.1,5.7c0.1,0,0.2,0,0.3,0c1.4,0,2.7-0.5,3.7-1.5v-2.8h-4.8v-1.5h6.5v5c-1.4,1.6-3.4,2.4-5.5,2.4
	c-3.9,0.1-7.1-3-7.2-6.8c0-0.1,0-0.3,0-0.4c-0.1-3.9,2.9-7.1,6.7-7.2C161.2,36.2,161.3,36.2,161.4,36.2"
    ></path>
    <path
      id="Path_224"
      :class="[modeClass]"
      d="M175.3,44.8h-2.8v5.5h-1.7v-14h5.6c2.2-0.2,4.2,1.5,4.4,3.7c0,0.2,0,0.3,0,0.5
	c0.1,2.1-1.5,3.9-3.6,4.1l3.8,5.7h-2.1L175.3,44.8z M176.2,37.9h-3.7v5.3h3.7c1.5,0.1,2.7-1.1,2.8-2.5c0.1-1.5-1.1-2.7-2.5-2.8
	C176.4,37.9,176.3,37.9,176.2,37.9"
    ></path>
    <path
      id="Path_225"
      :class="[modeClass]"
      d="M193.3,47.3h-7.1l-1.2,3.1h-1.9l5.6-14h2.2l5.6,14h-1.9L193.3,47.3z M186.8,45.7h6l-3-7.8
	L186.8,45.7z"
    ></path>
    <path
      id="Path_226"
      :class="[modeClass]"
      d="M199.4,36.4h9.1v1.5h-7.4v4.5h7.3V44h-7.3v6.4h-1.7L199.4,36.4z"
    ></path>
    <path
      id="Path_227"
      :class="[modeClass]"
      d="M211.9,36.4h9.1v1.5h-7.4v4.5h7.3V44h-7.3v6.4h-1.7L211.9,36.4z"
    ></path>
    <path
      id="Path_228"
      :class="[modeClass]"
      d="M230.8,36.2c4.1,0,6.9,3.1,6.9,7.2s-2.8,7.2-6.9,7.2c-4.1,0-6.9-3.1-6.9-7.2
	S226.7,36.2,230.8,36.2 M230.8,37.7c-3.1,0-5.1,2.4-5.1,5.7c0,3.2,2,5.7,5.1,5.7s5.1-2.4,5.1-5.7C235.9,40.1,233.9,37.7,230.8,37.7"
    ></path>
    <path
      id="Path_229"
      :class="[modeClass]"
      d="M246,44.8h-2.8v5.5h-1.7v-14h5.6c2.2-0.2,4.2,1.5,4.4,3.7c0,0.2,0,0.3,0,0.5
	c0.1,2.1-1.5,3.9-3.6,4.1l3.8,5.7h-2.1L246,44.8z M246.9,37.9h-3.7v5.3h3.7c1.5,0.1,2.7-1.1,2.8-2.5c0.1-1.5-1.1-2.7-2.5-2.8
	C247.1,37.9,247,37.9,246.9,37.9"
    ></path>
    <path
      id="Path_230"
      :class="[modeClass]"
      d="M255.3,36.4h6.2c2.4,0,3.9,1.5,3.9,3.6c0.1,1.5-1,2.9-2.4,3.2c1.6,0.4,2.7,1.8,2.7,3.4
	c0,2.2-1.5,3.8-4,3.8h-6.4L255.3,36.4L255.3,36.4z M261.2,42.4c1.2,0.1,2.3-0.7,2.4-1.9c0-0.1,0-0.2,0-0.3c0-1.2-0.9-2.2-2.1-2.3
	c-0.1,0-0.2,0-0.3,0H257v4.5H261.2z M261.3,48.8c1.3,0.2,2.4-0.7,2.6-2c0-0.1,0-0.3,0-0.4c0-1.3-1-2.4-2.3-2.4c-0.1,0-0.2,0-0.3,0
	H257v4.8L261.3,48.8z"
    ></path>
    <path
      id="Path_231"
      :class="[modeClass]"
      d="M269.5,36.4h1.7v8.5c0,2.6,1.4,4.2,4,4.2s4-1.6,4-4.2v-8.5h1.7v8.5c0,3.5-1.9,5.7-5.7,5.7
	s-5.7-2.2-5.7-5.7V36.4z"
    ></path>
    <path
      id="Path_232"
      :class="[modeClass]"
      d="M286.9,39.1v11.3h-1.7v-14h1.8l8,11v-11h1.7v14H295L286.9,39.1z"
    ></path>
    <path
      id="Path_233"
      :class="[modeClass]"
      d="M301,36.4h4.8c3.7-0.2,6.9,2.7,7.1,6.4c0,0.2,0,0.4,0,0.6c0.1,3.7-2.8,6.9-6.5,7
	c-0.2,0-0.4,0-0.6,0H301V36.4z M305.8,48.8c2.8,0.1,5.2-2.1,5.4-4.9c0-0.2,0-0.3,0-0.5c0.2-2.8-1.9-5.2-4.7-5.4c-0.2,0-0.4,0-0.6,0
	h-3v10.9L305.8,48.8z"
    ></path>
    <path
      id="Path_234"
      :class="[modeClass]"
      d="M58.8,30.8c0,10.6-8.6,19.2-19.2,19.2s-19.2-8.6-19.2-19.2S29,11.6,39.6,11.6
	S58.8,20.2,58.8,30.8L58.8,30.8"
    ></path>
    <path
      id="Path_235"
      :class="[modeClass]"
      d="M72.8,11.6c0,2.9-2.4,5.3-5.3,5.3s-5.3-2.4-5.3-5.3s2.4-5.3,5.3-5.3C70.4,6.4,72.8,8.7,72.8,11.6
	L72.8,11.6"
    ></path>
    <path
      id="Path_236"
      :class="[modeClass]"
      d="M0.7,31.5c-0.4,0-0.7-0.3-0.7-0.6V8.1C0,3.6,3.6,0,8.1,0h22.7c0.4,0,0.7,0.3,0.7,0.7
	s-0.3,0.7-0.7,0.7H8.1c-3.8,0-6.8,3-6.8,6.8l0,0v22.7C1.3,31.2,1,31.5,0.7,31.5L0.7,31.5"
    ></path>
    <path
      id="Path_237"
      :class="[modeClass]"
      d="M71.1,61.7H48.3c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7l0,0H71c3.8,0,6.8-3,6.8-6.8V30.9
	c0-0.4,0.3-0.7,0.7-0.7s0.7,0.3,0.7,0.7l0,0v22.7C79.2,58.1,75.5,61.7,71.1,61.7"
    ></path>
  </svg>
</template>
<style>
.ff-light {
  fill: #ffffff;
}
.ff-dark {
  fill: #000000;
}
.ff-primary {
  fill: var(--color-primary);
}
</style>
